export function getFilters(){
    return {
        id: "external_campaigns.id",
        name: "name",
        campaign_type: "type.description",
        brand: "brand",
        start_date: "start_date",
        end_date: "end_date",
        budget: "budget",
        kpi: "kpi",
        status: "status.description"
    }
}

export function getOptions(){
    return {
        id: "external_campaigns.id",
        name: "name",
        campaign_type: "type.description",
        brand: "brand",
        start_date: "start_date",
        end_date: "end_date",
        budget: "budget",
        kpi: "kpi",
        status: "status.description"
    }
}